import axios, {AxiosInstance} from "axios";

const defaultHeaders = {
	"Authorization": process.env.REACT_APP_AUTHORIZATION ?? "8IxsNZwp8MCfhX7mo1RddcVJ9%Mn@6VC#flXzQJS",
	"Content-Type": "application/json; charset=utf-8"
};

export const Api: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_API ?? "https://dev-api.icrd.com.br/api",
	headers: defaultHeaders,
	maxContentLength: Infinity,
});
