import {Dialog, DialogBody, DialogFooter, DialogHeader, Textarea, Typography} from "@material-tailwind/react";
import {IListarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IListarRequerimentoResult";
import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import ERotas from "enums/ERotas";
import {useNavigate} from "react-router-dom";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import {ToastHelper} from "helpers/ToastHelper";
import {ISolicitarCancelamentoRequerimentoCommand} from "interfaces/Commands/RequerimentosCommands/ISolicitarCancelamentoRequerimentoCommand";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import {RequerimentosService} from "services/RequerimentosService";
import { BaseHelper } from "helpers/BaseHelper";

interface IProps {
	requerimento?: IListarRequerimentoResult;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

export default function ModalRequerimentoSolicitarCancelamento(props: IProps) {
	const {
		requerimento,
		open,
		setOpen,
		setAtualizarLista
	} = props;
	const navigate = useNavigate();
	const tokenVisao = localStorage.getItem("tokenLogado");

	const [motivoCancelamento, setMotivoCancelamento] = useState<string>("");
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleEnviarFormularioMotivoCancelamento = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: ISolicitarCancelamentoRequerimentoCommand = {
				token: requerimento?.token ?? "",
				motivo_cancelamento: motivoCancelamento
			};

			const result = await RequerimentosService.solicitarCancelamento(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return;
				}

				ToastHelper("warning", errors[0].message);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Solicitação de Cancelamento realizada com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	return (
		<>
			<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
				<DialogHeader>Solicitar Cancelamento # {requerimento?.id}</DialogHeader>
				<DialogBody>
					<Typography variant={"small"} className={"font-monospace text-justify"}>
						Digite abaixo o motivo de cancelamento.
					</Typography>
					&emsp;
					<form id={"formSolicitacaoCancelamento"} onSubmit={handleEnviarFormularioMotivoCancelamento} noValidate>
						<Textarea
							color={"blue"}
							label="Motivo do Cancelamento"
							value={motivoCancelamento}
							variant="outlined"
							size={"md"}
							required={true}
							onChange={(e) => setMotivoCancelamento(e.target.value)}
						/>
					</form>
				</DialogBody>
				<DialogFooter className={"space-x-2"}>
					<ButtonDefaultComponent color={"green"} description={"Fechar"} onClick={() => setOpen(false)}/>
					<ButtonSubmitComponent form={"formSolicitacaoCancelamento"} description={"Solicitar Cancelamento"} color={"red"} desabilitar={desabilitarBotao}/>
				</DialogFooter>
			</Dialog>
		</>
	);
}