import {IListarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IListarRequerimentoResult";
import {useNavigate} from "react-router-dom";
import {BaseHelper} from "helpers/BaseHelper";
import {Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import ERotas from "enums/ERotas";
import React, {Dispatch, SetStateAction, useState} from "react";
import {LogErrorHelper} from "../../../../../helpers/LogErrorHelper";
import EStrings from "../../../../../enums/EStrings";
import {ToastHelper} from "../../../../../helpers/ToastHelper";
import {IAprovarRollbackRequerimentoCommand} from "interfaces/Commands/RequerimentosCommands/IAprovarRollbackRequerimentoCommand";
import {RequerimentosService} from "services/RequerimentosService";

interface IProps {
	requerimento?: IListarRequerimentoResult;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

export default function ModalRequerimentoRollback(props: IProps) {
	const {
		requerimento,
		open,
		setOpen,
		setAtualizarLista
	} = props;

	const navigate = useNavigate();
	const tokenVisao = localStorage.getItem("tokenLogado");

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleAprovarRollback = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IAprovarRollbackRequerimentoCommand = {
				token: requerimento?.token ?? ""
			};

			const result = await RequerimentosService.aprovarRollback(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);

				setDesabilitarBotao(false);
				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Rollback realizado com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>Rollback do Requerimento # {requerimento?.id}</DialogHeader>
			<DialogBody className={"space-y-3"}>
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					Tem certeza que deseja retornar este requerimento para o status anterior?
				</Typography>
				<Typography variant={"small"} className={"font-monospace font-bold text-justify"} color={"red"}>
					OBS.: ESTA AÇÃO É IRREVERSÍVEL.
				</Typography>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefaultComponent color={"green"} description={"Fechar"} onClick={() => setOpen(false)} desabilitar={desabilitarBotao}/>
				<ButtonDefaultComponent color={"red"} description={"Confirmar"} onClick={() => handleAprovarRollback()} desabilitar={desabilitarBotao}/>
			</DialogFooter>
		</Dialog>
	);
}