import {IListarTermoResult} from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Textarea, Typography} from "@material-tailwind/react";
import {BaseHelper} from "helpers/BaseHelper";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import EStrings from "enums/EStrings";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import ERotas from "enums/ERotas";
import {ToastHelper} from "helpers/ToastHelper";
import {TermosService} from "services/TermosService";
import {IAprovarFinalizacaoTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarFinalizacaoTermoCommand";
import {HiOutlineDownload} from "react-icons/hi";
import {IDownloadTermoAprovacaoCommand} from "interfaces/Commands/TermosCommands/IDownloadTermoAprovacaoCommand";
import {FileHelper} from "helpers/FileHelper";
import {IReprovarFinalizacaoTermoCommand} from "interfaces/Commands/TermosCommands/IReprovarFinalizacaoTermoCommand";

interface IProps {
	termo?: IListarTermoResult;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

export default function ModalTermoVisualizarFinaliza(props: IProps) {
	const {
		termo,
		open,
		setOpen,
		setAtualizarLista
	} = props;

	const navigate = useNavigate();
	const tokenVisao = localStorage.getItem("tokenLogado");

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const [motivoReprovacao, setMotivoReprovacao] = useState<string>("");
	const [visualizarMotivoReprovacao, setVisualizarMotivoReprovacao] = useState<boolean>(false);
	const [etapaReprovacao, setEtapaReprovacao] = useState<boolean>(false);

	const handleDownloadFinaliza = async (tokenTermo: string) => {
		const params: IDownloadTermoAprovacaoCommand = {
			token: tokenTermo
		};

		try {
			const result = await TermosService.downloadAprovacao(params, tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			ToastHelper("success", EStrings.DOWNLOAD_SUCESSO);
			FileHelper.show(body.data.base64, body.data.mimetype);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return;
		}
	};

	const handleReprovarFinalizacao = async () => {
		if (etapaReprovacao) {
			confimarReprovarFinalizacao();
		} else {
			setEtapaReprovacao(true);

			setVisualizarMotivoReprovacao(true);
		}
	};

	const confimarReprovarFinalizacao = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IReprovarFinalizacaoTermoCommand = {
				token: termo?.token ?? "",
				motivo_reprovacao: motivoReprovacao
			};

			const result = await TermosService.reprovarFinalizacao(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setOpen(false);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setOpen(false);
					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				setOpen(false);
				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setOpen(false);
				return;
			}

			ToastHelper("success", "Solicitação de Finalização reprovada com sucesso.");
			setOpen(false);
			setAtualizarLista(true);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);

				setOpen(false);
				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		} finally {
			setDesabilitarBotao(false);
		}
	};

	const handleAprovarFinalizacao = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IAprovarFinalizacaoTermoCommand = {
				token: termo?.token ?? ""
			};

			const result = await TermosService.aprovarFinalizacao(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Finalização aprovada com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	return (
		<>
			<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
				<DialogHeader>Aprovar a finalização do termo # {termo?.id}</DialogHeader>
				<DialogBody className={"space-y-2"}>
					<Typography variant={"small"} className={"font-monospace text-justify"}>
						O Celebrante enviou o termo para finalização.
					</Typography>
					<Typography variant={"small"} className={"font-monospace text-justify"}>
						Segue abaixo o link de download:
					</Typography>
					<Button fullWidth={true} className={"flex gap-3 items-center"} size={"sm"} variant={"gradient"} color={"blue"} onClick={() => handleDownloadFinaliza(termo?.token ?? "")}>
						<HiOutlineDownload className={"w-4 h-4 ml-auto"}/>
						<Typography variant={"small"} className={"font-normal mr-auto"}>
							Baixar Termo
						</Typography>
					</Button>
					<Typography variant={"small"} className={"font-monospace font-bold text-justify"} color={"red"}>
						OBS.: ESTA AÇÃO É IRREVERSÍVEL.
					</Typography>

					{visualizarMotivoReprovacao &&
						<>
							<Typography variant={"small"} className={"font-monospace text-justify"}>
								Digite abaixo o motivo de reprovação da finalização do termo.
							</Typography>
							&emsp;
							<form id={"formSolicitacaoReprovacao"} onSubmit={handleReprovarFinalizacao} noValidate>
								<Textarea
									color={"blue"}
									label="Motivo do Reprovação"
									value={motivoReprovacao}
									variant="outlined"
									size={"md"}
									required={true}
									onChange={(e) => setMotivoReprovacao(e.target.value)}
								/>
							</form>
						</>
					}
				</DialogBody>
				<DialogFooter className={"space-x-2"}>
					<ButtonDefaultComponent color={"red"} description={"Reprovar"} onClick={handleReprovarFinalizacao} desabilitar={desabilitarBotao}/>
					<ButtonDefaultComponent color={"green"} description={"Aprovar"} onClick={handleAprovarFinalizacao} desabilitar={desabilitarBotao}/>
				</DialogFooter>
			</Dialog>
		</>
	);
}